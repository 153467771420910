<template>
  <div class="site">
    <main class="site-content is-flex is-flex-direction-column">
      <div
        v-if="$store.state.user && $store.state.user.space == 'menu'"
        class="menu"
      >
        <section class="roomHeader home-menu-header" :style="sectionStyles">
          <div class="is-flex is-justify-content-center" :style="headerStyles">
            <img
              v-if="
                $store.state.setup.headerLogo &&
                !$store.state.setup.isMenuLogoWidescreen
              "
              class="logo"
              :src="srcHeaderMenu"
            />
            <span v-if="!$store.state.setup.headerLogo">{{ appTitle }}</span>
          </div>
        </section>
        <menu-component class="main-section" ref="mainSection">
        </menu-component>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appTitle: process.env.VUE_APP_TITLE,
    };
  },
  computed: {
    srcHeaderMenu() {
      return (
        this.$store.state.setup.headerLogo.sizes.mobile.url ||
        this.$store.state.setup.headerLogo.url
      );
    },
    isLogoWidescreen() {
      return (
        this.$store.state.setup?.headerLogo &&
        this.$store.state.setup?.isMenuLogoWidescreen
      );
    },
    sectionStyles() {
      if (this.isLogoWidescreen) {
        return {
          paddingInline: "unset",
        };
      } else return null;
    },
    headerStyles() {
      if (this.isLogoWidescreen) {
        return {
          backgroundImage: `url(${this.srcHeaderMenu})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          width: "100%",
          height: "100%",
        };
      } else return null;
    },
  },
};
</script>

<style scoped>
img {
  max-height: 80px;
  max-width: clamp(200px, 450px, 100%);
}
</style>
